import * as React from "react"
import { useEffect } from "react"
import { navigate } from 'gatsby';
import { Spinner } from "react-bootstrap"

const IndexPage = () => {

    useEffect(() => {
        navigate(`/es`);
      })

    return (
        <Spinner animation="border">
            <title>Clínica veterinaria La Huella Verde</title>
        </Spinner>
    )
};

export default IndexPage;
